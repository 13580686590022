import { useCallback, useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import CopyLinkToClipboard from '../click-to-copy';
import Button from '@material-ui/core/Button';
import InstntLogo from 'common/assets/InstntLogo.png';
import { Avatar } from '@mui/material';

const getDeviceType = (userAgent: any) => {
  const ua = userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

const VerifiableCredentialInvitation = ({
  invitationType,
  action = 'signup',
  transactionId,
  apiUrl,
  customText,
  invitation_url = null
}: {
  invitationType: 'issuer' | 'verifier';
  action?: 'authenticate' | 'signup';
  transactionId?: string | null;
  apiUrl?: string | null;
  customText: string | null;
  invitation_url: string | null;
}) => {
  const [invitationUrl, setInvitationUrl] = useState<string>('');
  const [showDeepLink, setShowDeepLink] = useState(false);

  useEffect(() => {
    if (invitation_url) {
      setInvitationUrl(invitation_url);
      return;
    }
    fetchVC();
  }, [transactionId]);

  useEffect(() => {
    const userAgent = (window as any)?.navigator.userAgent;
    const deviceType = getDeviceType(userAgent);
    if (deviceType !== 'desktop') {
      setShowDeepLink(true);
    }
  }, [invitationUrl]);

  const fetchVC = async () => {
    try {
      let invitationResponse;
      if (!transactionId) {
        return;
      }
      if (action == 'signup' && invitationType == 'issuer') {
        invitationResponse = await (
          window as any
        )?.instnt.getInvitationURLForVC(transactionId);
        setInvitationUrl(invitationResponse);
      } else if (action == 'signup' && invitationType == 'verifier') {
        invitationResponse = await (
          window as any
        )?.instnt.getInvitationURLForSignup(transactionId);
        setInvitationUrl(invitationResponse);
      } else if (action == 'authenticate' && invitationType == 'verifier') {
        invitationResponse = await (
          window as any
        )?.instnt.getInvitationURLForLogin(transactionId);
        setInvitationUrl(invitationResponse);
      } else {
        setInvitationUrl('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openVCInvitation = () => {
    if (window && invitationUrl) {
      window.open(invitationUrl, '_blank');
    }
  };

  const downloadVC = showDeepLink ? (
    <>
      <Button
        onClick={openVCInvitation}
        startIcon={<Avatar alt="Instnt Access Logo" src={InstntLogo} />}
      >
        {action === 'signup' ? 'Accept Instnt Pass' : 'Use Instnt Pass'}
      </Button>
      {action === 'signup' ? (
        <span className="deep-link-help-text">
          Scan with your wallet to signup with your verifiable credential
        </span>
      ) : (
        <span className="deep-link-help-text">
          SignIn with verifiable credential stored in your wallet
        </span>
      )}
    </>
  ) : invitationUrl ? (
    <div className="lead vc-invitation text-center text-md-left text-muted mb-6 mb-lg-8">
      {customText ? (
        <div>
          <b>{customText}</b>
        </div>
      ) : (
        <CopyLinkToClipboard
          url={invitationUrl}
          text="Download your verifiable credential to your wallet"
        />
      )}
      <br />
      <QRCode
        value={invitationUrl}
        size={256}
        logoImage={'https://www.instnt.org/hubfs/Favicon.png'}
        removeQrCodeBehindLogo={false}
        logoOpacity={0.5}
        logoWidth={100}
        qrStyle={'dots'}
      />
      <br />
      <br />
    </div>
  ) : null;

  return downloadVC;
};

export default VerifiableCredentialInvitation;
