import { useRef, useState, useEffect } from 'react';
import { InstntSignupProvider } from '@instnt/instnt-react-js';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useHistory } from 'react-router-dom';
import { FORM_ID, ID_METRIC_VERSION, SERVICE_URL } from '../config';

import { useContext } from 'react';
import AppContext from '../AppContext';
import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  BLANK,
  LOGIN,
  PASSWORD_ERROR,
  USERNAME_ERROR,
  VC_CUSTOM_TEXT
} from 'components/header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './signinStyles.scss';
import Profile from 'components/profile';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0088BB'
    }
  }
});

const CircularIndeterminate = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="primary" />
      </Box>
    </ThemeProvider>
  );
};

interface UserProfile {
  first_name: string;
  last_name: string;
  loa?: string;
  email: string;
  instnt_id: string;
}

interface SignInProps {
  setUserName: (userName: string | null | undefined) => void;
  setGlobalSSIVariable: (isSSIEnable: boolean | false) => void;
  apiUrl: string;
}

interface State {
  userName?: string;
  password?: string;
  showPassword?: boolean;
}

const SignIn = ({ setUserName, setGlobalSSIVariable, apiUrl }: SignInProps) => {
  const [localTransactionId, setLocalTransactionId] = useState<
    string | undefined
  >();
  const [loginSessionId, setLoginSessionId] = useState('');
  const [isSSIEnabled, setIsSSIEnable] = useState(false);
  const [invitationURL, setInvitationURL] = useState('');
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [localSSIVariable, setLocalSSIVariable] = useState(false);
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const [values, setValues] = useState<State>({});

  const instntRef = useRef(null);

  let loginPollEventsInterval: string | number | NodeJS.Timer | undefined;

  const onEventHandler = (event: any) => {
    switch (event.event_type || event.type) {
      case 'transaction.initiated':
        try {
          instntRef.current = event.data.instnt;
          setLocalTransactionId(event.data.instnt.instnttxnid);
          setLocalSSIVariable(event.data.instnt.isAsync);
          setGlobalSSIVariable(event.data.instnt.isAsync);
        } catch (e) {}
        break;

      case 'authentication.success':
        setLoggedIn(true);
        setUserProfile(event.data);
        break;

      case 'authentication.failed':
        setLoggedIn(false);
        setError('Authentication Failed. Try again');
        break;

      default:
        console.log(`Unhandled Event: ${JSON.stringify(event)} `);
    }
  };

  const onLoginEventHandler = (event: any) => {
    switch (event.event_type || event.type) {
      case 'LOGIN_INITIATED':
        setLoginSessionId(event.data?.login_session_id);
        if (event.data?.invitation_url) {
          setInvitationURL(event.data?.invitation_url);
          setIsSSIEnable(true);
        }
        break;

      case 'LOGIN_FAILED':
        setIsError(true)
        setError(event.data.message);
        break;

      case 'authentication.success':
        setLoggedIn(true);
        setUserProfile(event.event_data || event.data);
        break;

      case 'authentication.failed':
        setLoggedIn(false);
        setIsError(true);
        setError('Authentication Failed. Try again');
        break;

      default:
        console.log(`Unhandled Event: ${JSON.stringify(event)} `);
    }
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const isInputValueNotValid = (inputKey: string | undefined) => {
    if (inputKey) {
      return inputKey.length ? false : true;
    } else if (!inputKey) {
      return inputKey !== '' ? false : true;
    } else {
      return false;
    }
  };

  const handleLogin = () => {
    const formData = {
      first_name: values.userName || '',
      instnt_id: values.password || '',
      last_name: '',
      email: '',
      loa: ''
    };
    setLoggedIn(true);
    setUserProfile(formData);
  };
  const myContext = useContext(AppContext);

  const stopPollLoginEvents = () => {
    if (loginPollEventsInterval) {
      clearInterval(loginPollEventsInterval);
    }
  };
 /** Initiate Login */

  useEffect(() => {
    (async () => {
      let url = SERVICE_URL + '/public/initiate_login';
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          body: JSON.stringify({
            form_key: myContext.formKey
          })
        });
        const data = await response.json();
        if (data.status === 200) {
          onLoginEventHandler({
            type: 'LOGIN_INITIATED',
            data: data
          });
        } else {
          console.error('Error processing :', url);
          onLoginEventHandler({
            type: 'LOGIN_FAILED',
            data: {
              message: data?.detail[0]?.issue || 'Login Failed. Try with correct data',
              type: 'error'
            }
          });
        }
      } catch (error) {
        console.error('Error while initiating login process');
        onLoginEventHandler({
          type: 'LOGIN_FAILED',
          data: {
            message:
              'Received error: ' + error + ' while while initiating login',
            type: 'error'
          }
        });
      }
    })();
  }, [myContext.formKey]);

  /** Polling Mechanism */
  useEffect(() => {
    if (isSSIEnabled) {
      let pollEventlastTimestamp: any;

      const pollLoginEvents = async () => {
        let stopPolling = false;
        const terminalEventTypes = [
          'authentication.success',
          'authentication.failed'
        ];
        if (loginSessionId) {
          const url = `${SERVICE_URL}/public/transactions/${loginSessionId}/events?context=${encodeURIComponent('login')
}&from=${
            pollEventlastTimestamp || 0
          }`;
          try {
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                Accept: 'application/json'
              }
            });
            const data = await response.json();
            pollEventlastTimestamp = data.to;
            const events = data.events;

            for (const event of events) {
              if (
                event.event_type &&
                terminalEventTypes.includes(event.event_type)
              ) {
                onLoginEventHandler(event);
                stopPolling = true;
              }
            }
            stopPolling && stopPollLoginEvents();
          } catch (error) {
            console.error('Instnt : Error receiving events', error);
            stopPollLoginEvents();
          }
        }
      };

      loginPollEventsInterval = setInterval(pollLoginEvents, 3000);

    }

    return () => {
      stopPollLoginEvents();
    };
  }, [isSSIEnabled]);

  useEffect(() => {
    //
    return () => {
      stopPollLoginEvents();
      (instntRef.current as any)?.stopEventPolling();
      (myContext as any)?.saveSettings({
        workflowId: FORM_ID,
        idmetricVersion: ID_METRIC_VERSION,
        addressVerification: false,
        documentVerification: false,
        registerAccountWithSSI: false
      });
    };
  }, []);

  return (
    <div className="signup-container">
      {myContext.formKey &&
        !loggedIn &&
        (loginSessionId ? (
          <>
            <div className="login-header">
              <div className="login-container">
                <div>
                  <h1 className="login-header-text">
                    {'Login with Username and Password'}
                  </h1>
                </div>
                <div className="username-password-container">
                  <div className="username-field">
                    <TextField
                      required
                      id="outlined-search"
                      label="Username"
                      value={values.userName || ''}
                      error={isInputValueNotValid(values.userName)}
                      helperText={
                        isInputValueNotValid(values.userName)
                          ? USERNAME_ERROR
                          : BLANK
                      }
                      onChange={handleChange('userName')}
                      type="search"
                    />
                  </div>
                  <div className="password-field">
                    <TextField
                      required
                      id="outlined-search"
                      label="Password"
                      value={values.password}
                      error={isInputValueNotValid(values.password)}
                      helperText={
                        isInputValueNotValid(values.password)
                          ? PASSWORD_ERROR
                          : BLANK
                      }
                      onChange={handleChange('password')}
                      type="password"
                    />
                  </div>
                </div>
                <div className="login-button">
                  <div className="login-button-text">
                    <Button
                      onClick={() => handleLogin()}
                      disabled={
                        !(values.userName?.length && values.password?.length)
                      }
                    >
                      {LOGIN}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {isSSIEnabled && (
              <div>
                <div className="separator">
                  <h2>OR</h2>
                </div>
                <div className="login-vc-container">
                  <div className="login-with-VC">
                    <h2>Login with VC</h2>
                    <VerifiableCredentialInvitation
                      apiUrl={apiUrl}
                      invitationType="verifier"
                      action="authenticate"
                      transactionId={localTransactionId}
                      customText={VC_CUSTOM_TEXT}
                      invitation_url={invitationURL}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="insidepage-message">
              <div className={isError ? 'alert alert-danger' : 'alert alert-danger hide'}>    
                  <span className="messageSpan">{error}</span>
                </div>
            </div>
          </>
        ) : !isError ? CircularIndeterminate() : (
          <div className="outpage-message">
              <div className={isError ? 'alert alert-danger' : 'alert alert-danger hide'}>    
                  <span className="messageSpan">{error}</span>
                </div>
            </div>
        ))}
      {myContext.formKey && loggedIn && <Profile userProfile={userProfile} />}
    </div>
  );
};

export default SignIn;
