// import './styles.css';
import maintenance from 'common/assets/maintenance-mode.svg';
import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import './reviewStyles.scss';

const Review = ({
  transactionId,
  apiUrl,
  isSSIEnable
}: {
  transactionId?: string | null;
  apiUrl: string | null;
  isSSIEnable: any;
}) => {
  return (
    <>
      <aside className="review-feature-wrapper">
        <div className={'content'}>
          <h2 className="text-white">Your request is under review.</h2>
          <p className="text-white">
            Expect an email from us shortly with next steps.
          </p>
          {isSSIEnable && (
            <VerifiableCredentialInvitation
              invitationType="issuer"
              apiUrl={apiUrl}
              transactionId={transactionId}
              customText={null}
              invitation_url={null}
            />
          )}
        </div>
        <div className={'review-image'}>
          <img src={maintenance} className="marginTop" alt="alt review" />
        </div>
      </aside>
    </>
  );
};

export default Review;
