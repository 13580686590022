//import './styles.css';
import illustration from 'common/assets/illustration-2.png';
import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import { useEffect, useState } from 'react';
import './welcomeStyles.scss';

const Accept = ({
  transactionId,
  apiUrl,
  userName,
  isSSIEnable
}: {
  transactionId?: string | null;
  apiUrl: string | null;
  userName: string | null | undefined;
  isSSIEnable: boolean | false;
}) => {
  const [showDeepLink, setShowDeepLink] = useState(false);
  const DESKTOP = 'desktop';
  useEffect(() => {
    if ((window as any)?.instnt) {
      const userAgent = (window as any)?.instnt.userAgent;
      const deviceType = (window as any)?.instnt.getDeviceType(userAgent);
      if (deviceType !== DESKTOP) {
        setShowDeepLink(!showDeepLink);
      }
    }
  }, [transactionId]);

  return (
    <>
      <aside className="feature-wrapper">
        <div className={'content'}>
          <div className="welcome">
            <div className="amount-name-row">
              <div className="name-box">
                <p>Hello</p>
                <p>{`${userName}!`}</p>
              </div>
              <div className="amount-box">
                <p className="balance-title">Available Balance</p>
                <p className="balance">$ 0</p>
              </div>
            </div>
            <div className="vc-box">
              <div className="vc-box-container">
                <h1 className="text-center text-md-left">
                  Welcome to AcmeBank.
                  <br />
                  Banking anytime.
                </h1>
                <p className="lead text-center text-md-left text-muted mb-6 mb-lg-8">
                  Bank securely with us, 365 days (and nights) a year.
                </p>
                {isSSIEnable && (
                  <VerifiableCredentialInvitation
                    invitationType="issuer"
                    apiUrl={apiUrl}
                    transactionId={transactionId}
                    customText={
                      'Download your verifiable credential to your wallet'
                    }
                    invitation_url={null}
                  />
                )}
                {!showDeepLink ? (
                  <div className="text-center text-md-left">
                    <a
                      href="/welcome.html"
                      className="btn btn-primary lift transition-3d-hover"
                    >
                      Get Started
                    </a>{' '}
                    &nbsp;&nbsp;
                    <a
                      href="/welcome.html"
                      className="btn btn-primary-soft lift transition-3d-hover learn-more"
                    >
                      <span>
                        Learn More
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 20"
                          className="bi bi-chevron-right"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <img
              src={illustration}
              className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
              alt="illustration"
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default Accept;
