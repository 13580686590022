import { Switch, Route } from 'react-router-dom';
import { Routes } from 'common/enums';
import Header from './header';
import SignUp from './signup';
import HomePage from './homePage';
import Reject from './reject';
import Review from './review';
import Footer from './footer';
import Welcome from './welcome';
import Profile from './profile';

import './App.css';
import { useEffect, useState, useRef, SetStateAction } from 'react';
import AppContext from './AppContext';
import { FORM_ID, SERVICE_URL, ID_METRIC_VERSION } from './config';
import { IUserSettings } from 'common/models';
import SignIn from './signin';

function App() {
  const [formKey, setFormKey] = useState<string | null>(FORM_ID);
  const [idMetricVersion, setIdMetricVersion] = useState<string | null>(
    ID_METRIC_VERSION
  );
  const [addressVerification, setAddressVerification] =
    useState<boolean>(false);
  const [documentVerification, setDocumentVerification] =
    useState<boolean>(false);
  const [registerAccountWithSSI, setRegisterAccountWithSSI] =
    useState<boolean>(false);

  // const [transactionId, setTransactionId] = useState<string | null>(null);
  const transactionIdRef = useRef<string | null>();

  const ssiEnableRef = useRef<boolean>(false);

  const userNameRef = useRef<string | null>();

  const signUpUserNameRef = useRef<string | null>();

  const setUserNameRef = (userName: string | null | undefined) => {
    userNameRef.current = userName;
  };

  const setIsSSIEnableRef = (isSSIEnable: boolean | false) => {
    ssiEnableRef.current = isSSIEnable;
  };

  const setTransactionIdRef = (transactionId: string | null) => {
    transactionIdRef.current = transactionId;
  };

  const setSignUpUserNameRef = (userName: string | null | undefined) => {
    signUpUserNameRef.current = userName;
  };

  const saveSettings = (configGroup: {
    workflowId: SetStateAction<string | null>;
    idmetricVersion: SetStateAction<string | null>;
    addressVerification: boolean | ((prevState: boolean) => boolean);
    documentVerification: boolean | ((prevState: boolean) => boolean);
    registerAccountWithSSI: boolean | ((prevState: boolean) => boolean);
  }) => {
    setFormKey(configGroup?.workflowId);
    setIdMetricVersion(configGroup?.idmetricVersion);
    setAddressVerification(configGroup?.addressVerification);
    setDocumentVerification(configGroup?.documentVerification);
    setRegisterAccountWithSSI(configGroup?.registerAccountWithSSI);
  };
  const url = new URL(window.location.href);

  useEffect(() => {
    setFormKey(url.searchParams.get('form_key') || formKey);
    setIdMetricVersion(idMetricVersion);
    setAddressVerification(addressVerification);
    setDocumentVerification(documentVerification);
    setRegisterAccountWithSSI(registerAccountWithSSI);
  }, [url.searchParams]);

  const userSettings: IUserSettings = {
    formKey,
    idMetricVersion,
    addressVerification,
    documentVerification,
    registerAccountWithSSI,
    saveSettings
  };

  return (
    <AppContext.Provider value={userSettings}>
      <div className="App" id="main-container">
        <Header />
        <Switch>
          <Route path={Routes.DEFAULT} component={HomePage} exact={true} />
          <Route
            path={Routes.LOGIN}
            component={() => (
              <SignIn
                setUserName={setUserNameRef}
                setGlobalSSIVariable={setIsSSIEnableRef}
                apiUrl={SERVICE_URL}
              />
            )}
          />
          <Route
            path={Routes.SIGNUP}
            component={() => (
              <SignUp
                setTransactionId={setTransactionIdRef}
                setGlobalSSIVariable={setIsSSIEnableRef}
                setSignUpUserName={setSignUpUserNameRef}
                apiUrl={SERVICE_URL}
              />
            )}
          />
          <Route
            path={Routes.REJECT}
            component={() => (
              <Reject
                transactionId={transactionIdRef.current}
                apiUrl={SERVICE_URL}
              />
            )}
          />
          <Route
            path={Routes.REVIEW}
            component={() => (
              <Review
                transactionId={transactionIdRef.current}
                apiUrl={SERVICE_URL}
                isSSIEnable={ssiEnableRef.current}
              />
            )}
          />
          <Route
            path={Routes.WELCOME}
            component={() => (
              <Welcome
                transactionId={transactionIdRef.current}
                apiUrl={SERVICE_URL}
                userName={signUpUserNameRef.current}
                isSSIEnable={ssiEnableRef.current}
              />
            )}
          />
          <Route
            path={Routes.PROFILE}
            component={() => <Profile userProfile={{}} />}
          />
        </Switch>
        <Footer />
      </div>
    </AppContext.Provider>
  );
}

export default App;
