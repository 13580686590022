import { useEffect, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { InstntSignupProvider } from '@instnt/instnt-react-js';
import {
  steps,
  stepsInputs,
  stepsHeaders,
  stepsIncludeOTP,
  stepsHeadersIncludeOTP,
  stepsInputsIncludeOTP
} from 'common/consts/index';
import ProgressStepper from 'common/components/stepper';
import { SignupData } from 'common/models';
import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import StepItem from './step-item';
import {
  validateEmail,
  validatePhone,
  validateZipCode
} from '../../common/helpers';
import { FORM_ID, ID_METRIC_VERSION, SERVICE_URL } from '../config';
import AppContext from '../AppContext';
import './signupStyles.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
interface SignUpProps {
  setTransactionId: (transactionId: string | null) => void;
  setGlobalSSIVariable: (isSSIEnable: boolean | false) => void;
  setSignUpUserName: (userName: string | null | undefined) => void;
  apiUrl: string;
}

const TRANSACTION_FAILED_ERROR_MESSAGE =
  'Error processing signup request. Please retry and if the issue persist, contact ';
const INSTNT_SUPPORT_LINK = 'support@instnt.org';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0088BB'
    }
  }
});

const CircularIndeterminate = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="primary" />
      </Box>
    </ThemeProvider>
  );
};

const nextButtonDisable = ({ numStep, data, isOtpVerificationEnable }: any) => {
  if (isOtpVerificationEnable) {
    switch (numStep) {
      case 0:
        return data.firstName && data.surName;
      case 1:
        return validateEmail(data.email) && validatePhone(data.mobileNumber);
      case 2:
        return data.code;
      case 3:
        return data.physicalAddress && data.city && validateZipCode(data.zip);
      default:
        return true;
    }
  } else {
    switch (numStep) {
      case 0:
        return data.firstName && data.surName;
      case 1:
        return validateEmail(data.email) && validatePhone(data.mobileNumber);
      case 2:
        return data.physicalAddress && data.city && validateZipCode(data.zip);
      default:
        return true;
    }
  }
};

const SignUp = ({
  setTransactionId,
  setGlobalSSIVariable,
  setSignUpUserName,
  apiUrl
}: SignUpProps) => {
  const [data, setData] = useState<SignupData>({});
  const [localTransactionId, setLocalTransactionId] = useState<
    string | undefined
  >();
  const [processing, setProcessing] = useState(false);
  const [localSSIVariable, setLocalSSIVariable] = useState(false);
  const [_steps, setSteps] = useState(steps);
  const [_stepsInputs, setStepsInput] = useState(stepsInputs);
  const [_stepsHeaders, setStepsHeaders] = useState(stepsHeaders);
  const instntRef = useRef(null);
  const [otpEnable, setOTPEnable] = useState(false);
  const [numStep, setNumStep] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const onChange = (element: any, value = null) => {
    setIsError(false);
    if (typeof element === 'string') {
      setData({ ...data, [element]: value });
    } else {
      setData({ ...data, [element.target.name]: element.target.value });
    }
  };

  const onNextStep = () => {
    setIsError(false);
    if (numStep === 1 && otpEnable) {
      setLoader(true);
      (instntRef.current as any).sendOTP(`+1${data.mobileNumber}`);
      return;
    }
    if (numStep === 2 && otpEnable) {
      setLoader(true);
      (instntRef.current as any).verifyOTP(`+1${data.mobileNumber}`, data.code);
      return;
    }
    setNumStep((prevStep) => prevStep + 1);
    numStep < _steps.length && setNextDisabled(true);
  };

  const onBackStep = () => {
    setIsError(false);
    setNumStep((prevStep) => prevStep - 1);
    setNextDisabled(false);
  };

  const getCountryNameCode = (country: string | undefined) => {
    if (country === 'Canada') {
      return 'CA';
    } else if (country === 'United States') {
      return 'US';
    } else {
      return 'CA'; // as default value set to canada
    }
  };

  const onSubmit = function () {
    setLoader(true);
    setIsError(false);
    setSignUpUserName(data?.firstName);
    const countryCode = getCountryNameCode(data?.country);
    (window as any).instnt.submitSignupData({
      ...data,
      country: countryCode,
      mobileNumber: `1${data.mobileNumber}`,
      nationalId: '111-11-1111',
      dob: '2023-08-21'
    });
  };

  const onEventHandler = (event: any) => {
    switch (event.type) {
      case 'transaction.initiated':
        try {
          instntRef.current = event.data.instnt;
          setTransactionId(event.data.instnt.instnttxnid);
          setLocalSSIVariable(event.data.instnt.isAsync);
          setGlobalSSIVariable(event.data.instnt.isAsync);
          setLocalTransactionId(event.data.instnt.instnttxnid);
          setOTPEnable(event.data.instnt.otpVerification);
        } catch (e) {}
        break;

      case 'transaction.submitted':
        setProcessing(true);
        break;
      case 'otp.sent':
        console.log('event type otp sent', event);
        setLoader(false);
        setNumStep((prevStep) => prevStep + 1);
        break;
      case 'otp.verified':
        console.log('OTP Verified event triggered', event);
        setLoader(false);
        setNumStep((prevStep) => prevStep + 1);
        break;
      case 'otp.error':
        setLoader(false);
        setIsError(true);
        setErrorMessage(event?.data?.message);
        setProcessing(false);
        break;
      case 'transaction.processed':
      case 'transaction.accepted':
      case 'transaction.rejected':
      case 'transaction.review':
        switch (event.data.decision) {
          case 'ACCEPT':
            history.push('/welcome.html');
            break;
          case 'REJECT':
            history.push('/reject.html');
            break;
          case 'REVIEW':
            history.push('/review.html');
            break;
          default:
            break;
        }
        break;
      case 'transaction.error':
      case 'transaction.failed':
        setLoader(false);
        setIsError(true);
        setErrorMessage(event?.data?.message);
        setProcessing(false);
        break;

      default:
        console.log(`Unhandled Event: ${JSON.stringify(event)} `);
    }
  };

  const myContext = useContext(AppContext);

  useEffect(() => {
    if (otpEnable) {
      setSteps(stepsIncludeOTP);
      setStepsInput(stepsInputsIncludeOTP);
      setStepsHeaders(stepsHeadersIncludeOTP);
    }
  }, [otpEnable]);

  useEffect(() => {
    setNextDisabled(
      !nextButtonDisable({ numStep, data, isOtpVerificationEnable: otpEnable })
    );
  }, [data, numStep]);

  useEffect(() => {
    return () => {
      (instntRef.current as any)?.stopEventPolling();
      (myContext as any)?.saveSettings({
        workflowId: FORM_ID,
        idmetricVersion: ID_METRIC_VERSION,
        addressVerification: false,
        documentVerification: false,
        registerAccountWithSSI: false
      });
    };
  }, []);

  return (
    <div className="signup-page">
      <div className="signup-page-container">
        {myContext.formKey && (
          <InstntSignupProvider
            onEvent={onEventHandler}
            formKey={myContext.formKey}
            idmetrics_version={myContext.idMetricVersion}
            serviceURL={SERVICE_URL}
          >
            {localTransactionId ? (
              <>
                {!processing && (
                  <>
                    <StepItem
                      data={data}
                      isOtpVerificationEnable={otpEnable}
                      step={numStep}
                      stepInputs={_stepsInputs[numStep]}
                      stepLabels={_stepsHeaders[numStep]}
                      onChange={onChange}
                    />
                    <div className="onpage-message">
                      <div
                        className={
                          isError
                            ? 'alert alert-danger'
                            : 'alert alert-danger hide'
                        }
                      >
                        {isError &&
                        errorMessage &&
                        errorMessage.includes('support') ? (
                          <>
                            <span className="messageSpan">
                              {TRANSACTION_FAILED_ERROR_MESSAGE}
                            </span>
                            <a
                              href="https://support.instnt.org/hc/en-us"
                              target="_blank"
                            >
                              {INSTNT_SUPPORT_LINK}
                            </a>
                          </>
                        ) : (
                          <span className="messageSpan">{errorMessage}</span>
                        )}
                      </div>
                    </div>

                    <ProgressStepper
                      activeStep={numStep}
                      stepsLabels={_steps}
                      loader={loader}
                      nextDisabled={nextDisabled}
                      onNextStep={onNextStep}
                      onBackStep={onBackStep}
                      onSubmit={onSubmit}
                    />
                  </>
                )}
                {processing && (
                  <>
                    <div className="loader-dots" style={{ margin: 'auto' }}>
                      <ThreeDots color="#00BFFF" height={200} width={200} />
                    </div>
                    <div className="loader-text" style={{ margin: 'auto' }}>
                      Please hold while we are verifying your information
                    </div>
                  </>
                )}
              </>
            ) : (
              CircularIndeterminate()
            )}
          </InstntSignupProvider>
        )}
      </div>
      {numStep === 0 && localSSIVariable && (
        <>
          <div className="separator">
            <h2>OR</h2>
          </div>
          <div className="signup-page-vc-container">
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <>
                <h2>Onboard with your verifiable credential</h2>
                <VerifiableCredentialInvitation
                  apiUrl={apiUrl}
                  invitationType="verifier"
                  transactionId={localTransactionId}
                  customText={
                    'Scan with your wallet to signup with your verifiable credential'
                  }
                  invitation_url={null}
                />
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
