//import './styles.css';
import error404 from 'common/assets/error-404.svg';
import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import './rejectStyles.scss';

const Reject = ({
  transactionId,
  apiUrl
}: {
  transactionId: string | null | undefined;
  apiUrl: string | null;
}) => {
  return (
    <>
      <aside className="feature-wrapper-reject">
        <div className="content">
          <h2 className="text-danger content-center">
            We are unable to approve your application at this time.
          </h2>
          <p className="content-center">
            Please don't hesitate to try again after 30 days.
            <br />
            And reach out to us if you have any questions.
          </p>
          <div
            className="text-center text-md-left contact-button content-center"
            style={{ marginTop: '10px' }}
          >
            <a href="/" className="btn btn-primary lift transition-3d-hover">
              Contact Us
            </a>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Reject;
