import forbes from 'common/assets/forbes.png';
import wired from 'common/assets/wired.png';
import techCrunch from 'common/assets/techcrunch.png';
import americanBanker from 'common/assets/americanbanker-1.png';
import appStore from 'common/assets/app-store.svg';
import googlePlay from 'common/assets/play-badge.png';
import cash from 'common/assets/deposit_cash_icon.png';
import pay from 'common/assets/pay-friend_icon.png';
import safety from 'common/assets/security_icon.png';
import homepage from 'common/assets/homepage_background.jpg';
// @ts-ignore
import heroVideo from 'common/assets/acmebankhero.mp4';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faDownload,
  faCreditCard,
  faHandHoldingUsd,
  faShoppingBasket
} from '@fortawesome/free-solid-svg-icons';

//import "./styles.css";
import './homeStyles.scss';
import Slider from '../slider';
import { useEffect, useState } from 'react';
import manImage from '../../common/assets/man.png';
import addressCardR from '../../common/assets/address-card-regular.png';
import addressCardS from '../../common/assets/address-card-solid.png';
import barProgress from '../../common/assets/bars-progress-solid.png';
import chartPie from '../../common/assets/chart-pie-solid.png';
import magnifyingGlass from '../../common/assets/magnifying-glass-chart-solid.png';
import wallet from '../../common/assets/wallet-solid.png';
import { Routes } from 'common/enums';

const HomePage = () => {
  const [scroll, setScroll] = useState(0);
  const [start, setStart] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setStart(document.querySelector('.et_pb_section_0')?.clientHeight || 0);
      setScroll(window.pageYOffset);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <div className="home-screen">
      <div className="body-screen">
        <div className="man-image">
          <img src={manImage} alt="Instnt Man Image" />
        </div>
        <div className="body-content-text">
          <div className="top-text">
            <p>Get AcmeBank.</p>
            <p>Anywhere, anytime.</p>
          </div>
          <div className="bottom-text">
            <p>
              The AcmeBank account, card, and app work together to help you
              understand and manage your spending.
            </p>
          </div>
          <div className="nav-item">
            <nav className="body-menu-mav">
              <ul className="body-menu">
                <li className="menu-item">
                  <Link className="getStarted" to={Routes.SIGNUP}>
                    Get Started
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="body-screen-menu-item">
        <div id="icons-grid">
          <div className="hero-cell transition-3d-hover">
            <img src={addressCardR} alt="APPLY" />
            <p>APPLY</p>
          </div>
          <hr />
          <div className="hero-cell transition-3d-hover">
            <img src={barProgress} alt="LOAD" />
            <p>LOAD</p>
          </div>
          <hr />
          <div className="hero-cell transition-3d-hover">
            <img src={wallet} alt="SPEND" />
            <p>SPEND</p>
          </div>
          <hr />
          <div className="hero-cell transition-3d-hover">
            <img src={magnifyingGlass} alt="TRACK" />
            <p>TRACK</p>
          </div>
          <hr />
          <div className="hero-cell transition-3d-hover">
            <img src={chartPie} alt="CONTROL" />
            <p>CONTROL</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
