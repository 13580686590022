const insertAt = (array: any, index: number, ...elementsArray: any) => {
  const modifiedArray = [...array];
  modifiedArray.splice(index, 0, ...elementsArray);
  return modifiedArray;
};

const stepsInputs = [
  [
    {
      id: 'firstName',
      label: 'Given Name',
      autocomplete: 'given-name',
      validator: /^[a-zA-Z ]{2,50}$/
    },
    {
      id: 'surName',
      label: 'Last Name',
      autocomplete: 'family-name',
      validator: /^[a-zA-Z ]{2,50}$/
    }
  ],
  [
    {
      id: 'email',
      type: 'email',
      label: `What's your email address?`,
      autocomplete: 'email',
      validator:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    {
      id: 'mobileNumber',
      type: 'number',
      label: `What's your phone number?`,
      autocomplete: 'tel-national',
      validator: /([1-9]\d{2})(\D*)([1-9]\d{2})(\D*)(\d{4})\D*/,
      isPhone: true
    }
  ],
  [
    {
      id: 'country',
      label: 'Country:',
      options: ['Canada', 'United States'],
      default: 'United States',
      isSelect: true
    },
    {
      id: 'physicalAddress',
      label: `Street Address`
    },
    {
      id: 'city',
      label: `City`
    },
    {
      id: 'state',
      label: `State/Province`
    },
    {
      id: 'zip',
      label: `Zip/Postal Code`,
      validator: /^[0-9]{5}$|^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/ //US and Canada Zip Code Regex
    }
  ]
];
const stepsInputsIncludeOTP = insertAt(stepsInputs, 2, [
  {
    id: 'code',
    type: 'number',
    label: `Verification Code`
  }
]);

const stepsHeaders = [
  {
    header: 'Hello!',
    paragraph: 'Tell us your name:'
  },
  {
    paragraph: 'How to contact you?'
  },
  {
    paragraph: 'Where do you live?'
  }
];
const stepsHeadersIncludeOTP = insertAt(stepsHeaders, 2, {
  header: `Phone number verification`,
  paragraph: 'The verification code was sent to the number '
});

const steps = ['Hello!', 'How to contact you?', 'Where do you live?'];
const stepsIncludeOTP = insertAt(steps, 2, 'Phone number verification');

export {
  steps,
  stepsHeaders,
  stepsInputs,
  stepsIncludeOTP,
  stepsHeadersIncludeOTP,
  stepsInputsIncludeOTP
};
